<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@font-face {
  font-family: "Monument-bold";
  src: url("./assets/fonts/MONUMENTEXTENDED-ULTRABOLD.OTF");
  font-display: swap;
}

@font-face {
  font-family: "Monument-regular";
  src: url("./assets/fonts/MONUMENTEXTENDED-REGULAR.OTF");
  font-display: swap;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&family=Poppins:wght@300;400;500;600;700&display=swap&family=Inter:wght@400;500;600&display=swap");

:root {
  --color-1: #ff57da;
  --color-2: #7948ff;
  --color-3: #48deff;
  --color-4: #8d8d8d;
  --color-5: #ffffff;
  --color-6: #a17fff;
  --color-7: #f24e4e;
  --color-8: #a17fff;
  --color-9: #f54c49;
  --color-10: #3383b1;
  --color-11: #215675;

  --Poppins: "Poppins", sans-serif;
  --Lato: "Lato", sans-serif;

  /* Tipografias */
  --font-titulo1: "Monument-bold";
  --font-titulo2: "Monument-regular";
  --font-parrafo: "Inter", sans-serif;

  /* Header */

  /**validaciones */
  --fs-val: 0.6770833333333334vw;
  --fs-val-mo: 3.864734299516908vw;
  --error-val: #fa8f21;
  --success-val: #55cd00;

  /* Menu PC */
  --fs-menupc: 0.6770833333333334vw;
  --menu-bars: invert(82%) sepia(25%) saturate(4616%) hue-rotate(44deg)
    brightness(84%) contrast(111%);
  --co-menui-active: #55cd00;
  --co-menui: #000000;
  --im-border: 0.026vw solid #000000;
  --lupapc-color: invert(0%) sepia(4%) saturate(2%) hue-rotate(79deg)
    brightness(89%) contrast(101%);

  /* Menu MO */
  --fondo-bk: url("assets/fondomovil.png");
  --fs-menumo: 6.522vw;
  --color-menumo: #ffffff;
  --im-border-mo: 0.121vw solid #ffffff;
  --lupamo-color: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
    brightness(102%) contrast(102%);

  /* Social Media Icon */
  --sm-icon-color: invert(82%) sepia(25%) saturate(4616%) hue-rotate(44deg)
    brightness(84%) contrast(111%);
  --sm-icon-hover: invert(70%) sepia(99%) saturate(2429%) hue-rotate(343deg)
    brightness(98%) contrast(99%);

  /* Menu Movil */
  --fs-menumv: 16.521739130434782vw;

  /* Generales */
  --fs-idseccionpc: 0.625vw;
  --fs-idseccionmv: 2.657004830917874vw;
  --co-hr1seccion: #55cd00;
  --co-hr2seccion: #fa8f21;
  --co-h51seccion: #000000;
  --co-h52seccion: #ffffff;

  /* Seccion 1 PC */

  /* Seccion 1 Mov */

  /* Slide principal */
  --labelsl-color: white;
  --buttonsl-color: #55cd00;

  /* Seccion Nosotros PC */
  --fs-t1noso: 2.604166666666667vw;
  --co-t1noso: #55cd00;
  --fs-p1noso: 1.0416666666666665vw;
  --co-p1noso: #7b7b7b;
  --fs-p2noso: 0.7291666666666666vw;
  --co-p2noso: #8e8e8e;

  /* Seccion Nosotros Mov */
  --fs-t1nosomv: 8.454106280193237vw;
  --fs-p1nosomv: 4.3478260869565215vw;
  --fs-p2nosomv: 3.864734299516908vw;

  /* Seccion Servicios */
  --co-bgservicios: #1a1f98;
  --fs-t1serv: 3.802083333333333vw;
  --co-t1serv: #fa8f21;
  --fs-t2serv: 0.9375vw;
  --co-t2serv: #ffffff;
  --fs-h3serv: 0.9895833333333333vw;
  --co-h3serv: #55cd00;
  --fs-pserv: 0.7291666666666666vw;
  --co-pserv: #ffffff;
  --co-btnserv: #fa8f21;

  /* Seccion Servicios Movil*/
  --fs-t1servmv: 8.454106280193237vw;
  --fs-t2servmv: 4.3478260869565215vw;
  --fs-h3servmv: 5.314009661835748vw;
  --fs-pservmv: 4.3478260869565215vw;
  --fs-btnservmv: 3.864734299516908vw;

  /* Seccion contacto */
  --fs-titconta: 2.864583333333333vw;
  --co-titconta: #000000;
  --fs-titconta1: 2.34375vw;
  --co-titconta1: #ffffff;
  --fs-subconta: 1.0416666666666665vw;
  --fs-paconta: 1.0416666666666665vw;

  /* Seccion contacto movil */
  --fs-titcontamv: 10.869565217391305vw;
  --fs-titconta1mv: 8.454106280193237vw;
  --fs-subcontamv: 4.830917874396135vw;
  --fs-pacontamv: 4.830917874396135vw;

  /* Seccion siguenos */
  --fs-titsig: 3.6458333333333335vw;
  --fs-btnig: 1.0416666666666665vw;

  /* Seccion siguenos movil*/
  --fs-titsigmv: 8.454106280193237vw;
  --fs-btnigmv: 5.797101449275362vw;

  /* Seccion formulario */
  --fs-titform: 3.802083333333333vw;
  --co-titform: #000000;
  --fs-inpform: 0.8333333333333334vw;
  --co-inpform: #7b7b7b;
  --fs-adpform: 0.7291666666666666vw;
  --co-adpform: #000000;
  --co-linkadpform: #fa8f21;
  --fs-pabtnform: 0.9375vw;
  --co-btnform: #55cd00;
  --co-pabtnform: #fff;

  /* Seccion contacto movil*/
  --fs-titformmv: 8.454106280193237vw;
  --fs-inpformmv: 4.830917874396135vw;
  --fs-adpformmv: 4.3478260869565215vw;
  --fs-pabtnformmv: 4.3478260869565215vw;

  /*Seccion footer */
  --fs-titsecfo: 0.8333333333333334vw;
  --co-titsecfo: #55cd00;
  --fs-titinfofo: 0.8333333333333334vw;
  --co-titinfofo: #55cd00;
  --fs-txtinfofo: 0.8333333333333334vw;
  --co-txtinfofo: #ffffff;
  --fs-txtmenufo: 0.7291666666666666vw;
  --co-txtmenufo: #ffffff;

  /*Seccion footer Movil */
  --fs-titsecfomv: 5.314009661835748vw;
  --fs-pinfofomv: 4.830917874396135vw;
  --fs-txtmenufomv: 4.830917874396135vw;

  /* Copyright */
  --fs-txtcopy: 0.6770833333333334vw;
  --co-txtcopy: #ffffff;

  /* Copyright movil */
  --fs-txtcopymv: 3.140096618357488vw;
}

/* RESET */

#app {
  margin: 0vw;
  height: auto;
  font-family: var(--Poppins);
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}

.VueCarousel-navigation-button {
  color: var(--labelsl-color) !important;
  background-color: var(--buttonsl-color)!important;
  width: 1.771vw;
  height: 1.771vw;
  border-radius: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart_msg{
  font-size: var(--fs-val);
  font-family: var(--font-parrafo);
  margin: 0;
}
.cart_success{
  color: var(--success-val);
}
.cart_error{
  color: var(--error-val);
}
@media (max-width: 967px) {
  #app {
    margin: 0vw;
    height: 100vh;
    font-family: var(--Poppins);
  }
}
@media (max-width: 768px) {
.cart_msg{
  font-size: var(--fs-val-mo);
  text-align: center;
  margin: 5.45353vw 0;
}
}
body {
  margin: 0px;
}

input:focus-visible {
  outline: none !important;
}

input,
textarea,
select {
  padding-left: 0.5vw;
  outline: none;
}

.dflx {
  display: flex;
}

.dpl {
  display: none;
}

hr.hrhd {
  width: 70.72916666666667vw;
  margin-left: 0px;
  border-bottom: 0.2px solid #b1b1b1;
  margin-top: 1.6145833333333335vw;
  margin-bottom: 2.9166666666666665vw;
}

/** MENSAJES DE EXITO/ERROR */
.status_messages {
  margin-top: 1vw;
}

.msg p {
  color: var(--color-5);
  width: 20vw;
  padding: 0.5vw;
  font-family: var(--Poppins);
  font-size: 0.7333333333333334vw;
  font-style: normal;
}
.msg_success {
  background: var(--color-2);
  width: 20vw;
}

.msg_error p {
  background: var(--color-7);
  width: 20vw;
}

/** FIN MENSAJES DE EXITO/ERROR */

/** NAVIGATE BUTTONS */
#navigate-buttons {
  margin-left: 1.3vw;
  margin-top: 0.1vw;
}

#navigate-buttons button {
  border: 0px;
  background: var(--color-5);
  width: 1.5vw;
  height: 1.5vw;
  cursor: pointer;
  transition: all 300ms;
  color: var(--color-5);
  font-size: 1vw;
  margin-top: 0.7vw;
}

/*#navigate-buttons button:hover{
       
        background: var(--color-2);
       
        
    }*/
p.num-pages {
  color: #797979;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  padding-top: 0.2vw;
  font-size: 0.7vw;
}

/** FIN NAVIGATE BUTTONS */

/** MODAL delete*/

.modal_view {
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0vw;
  right: 0vw;
}
#modal_delete {
  /*background: var(--color-5);*/
  width: 16.383333vw;
  height: 8vw;
  position: absolute;
  top: 17vw;
  right: 40.5vw;
  padding: 0.8854166666666666vw;
}
#modal_delete .body {
  background: var(--color-1);
  width: 16.354166666666668vw;
  height: 6.354166666666666vw;
  border-radius: 1.5vw;
  justify-content: center;
}

#modal_delete h3 {
  font-size: 0.7291666666666666vw;
  color: var(--color-5);
  text-align: center;
  font-weight: 400;
  margin-top: 0vw;
}
#modal_delete h3 span {
  font-weight: 700;
}
#modal_delete img {
  margin-top: 1.0416666666666665vw;
  margin-left: 7.239583333333334vw;
  width: 1.9270833333333335vw;
  height: 1.8229166666666667vw;
}

.modal_del_btns {
  justify-content: center;
}

.modal_del_btns p {
  color: var(--color-2);
  text-decoration: underline;
  cursor: pointer;
  width: 5vw;
  text-align: center;
  padding: 0.5vw;
  margin: 0vw;
  font-size: 0.625vw;
}

/** fin modal delete */

/** modal add */
#modal_add {
  /*background: var(--color-5);*/
  width: 16.383333vw;
  height: 8vw;
  position: absolute;
  top: 17vw;
  right: 40.5vw;
  padding: 0.8854166666666666vw;
}
#modal_add .body {
  background: var(--color-1);
  width: 16.354166666666668vw;
  height: 2.3958333333333335vw;
  border-radius: 1.5vw;
}

#modal_add h3 {
  font-size: 0.7291666666666666vw;
  color: var(--color-5);
  padding-left: 1.5vw;
  font-weight: 400;
  padding-top: 0.6770833333333334vw;
  margin-top: 0vw;
  width: 10vw;
}
#modal_add img {
  padding-top: 0.54vw;
  padding-left: 3vw;
  width: 1.3031249999999999vw;
  height: 1.3031249999999999vw;
}

.modal_add_btns {
  justify-content: center;
}

.modal_add_btns p {
  color: var(--color-2);
  text-decoration: underline;
  cursor: pointer;
  width: 5vw;
  text-align: center;
  padding: 0.5vw;
  margin: 0vw;
  font-size: 0.625vw;
}
/** fin  modal add */

/** modal edit */
#modal_edit {
  /*background: var(--color-5);*/
  width: 16.383333vw;
  height: 8vw;
  position: absolute;
  top: 17vw;
  right: 40.5vw;
  padding: 0.8854166666666666vw;
}
#modal_edit .body {
  background: var(--color-1);
  width: 16.354166666666668vw;
  height: 2.3958333333333335vw;
  border-radius: 1.5vw;
}

#modal_edit h3 {
  font-size: 0.7291666666666666vw;
  color: var(--color-5);
  padding-left: 1.5vw;
  font-weight: 400;
  width: 13vw;
  padding-top: 0.6770833333333334vw;
  margin-top: 0vw;
}
#modal_edit img {
  padding-top: 0.54vw;

  width: 1.3031249999999999vw;
  height: 1.3031249999999999vw;
}

.img-item img {
  max-width: 15vw;
}

.modal_edit_btns {
  justify-content: center;
}

.modal_edit_btns p {
  color: var(--color-2);
  text-decoration: underline;
  cursor: pointer;
  width: 5vw;
  text-align: center;
  padding: 0.5vw;
  margin: 0vw;
  font-size: 0.625vw;
}
/** fin  modal add */

/** modal logout */

#modal_logout {
  /*background: var(--color-5);*/
  width: 16.383333vw;
  height: 8vw;
  position: absolute;
  top: 17vw;
  right: 40.5vw;
  padding: 0.8854166666666666vw;
}
#modal_logout .body {
  background: var(--color-2);
  width: 16.354166666666668vw;
  height: 6.354166666666666vw;
  border-radius: 1.5vw;
  justify-content: center;
}

#modal_logout h3 {
  font-size: 0.7291666666666666vw;
  color: var(--color-5);
  text-align: center;
  font-weight: 400;
  margin-top: 0.5vw;
}
#modal_logout h3 span {
  font-weight: 700;
}
#modal_logout img {
  margin-top: 1.0416666666666665vw;
  margin-left: 7.239583333333334vw;
  width: 1.875vw;
  height: 1.875vw;
}

.modal_log_btns {
  justify-content: center;
}

.modal_log_btns p {
  color: var(--color-2);
  text-decoration: underline;
  cursor: pointer;
  width: 5vw;
  text-align: center;
  padding: 0.5vw;
  margin: 0vw;
  font-size: 0.625vw;
}

p.noitems {
  color: var(--color-6);
  font-size: 0.625vw;
  font-weight: 500;
  width: 4.947916666666666vw;
  margin-right: 1.5625vw;
}
p.noitems span {
  font-weight: 700;
}

#select-items {
  width: 8.90625vw;
  border: 0px;
  background: var(--color-4);
  border-radius: 0.5vw;
  color: var(--color-5);
  font-size: 0.625vw;
  font-weight: 500;
  border: none;
  margin-right: 1.9270833333333335vw;
}
.activeradius {
  border-radius: 0.5vw 0.5vw 0vw 0vw !important;
}

#select-items option {
  background: white;
  color: #8d8d8d;
}
.buscador {
  margin-top: 0.2vw;
  height: 1.5625vw;
  border: 0.052083vw solid var(--color-4);
  border-radius: 0.5vw;
  padding-left: 0.5vw;
}
.buscador input {
  width: 15.312500000000002vw;
  border: none;
  height: 1.5625vw;
  color: #8d8d8d;
}
.icon_buscar img {
  width: 0.8854166666666666vw;
  height: 0.8854166666666666vw;
  padding-top: 0.3vw;
  margin-right: 0.5vw;
}

.btn_añadir {
  cursor: pointer;
  border: none;
  background: var(--color-1);
  color: var(--color-5);
  width: 15.416666666666668vw;
  height: 2.5vw;
  text-align: left;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
}
.btn_añadir img {
  width: 1.3031249999999999vw;
  height: 1.3031249999999999vw;
  margin-left: 8.5vw;
  padding-top: 0.6vw;
}
.btn_añadir p {
  font-weight: 500;
  font-size: 0.7291666666666666vw;
  padding-bottom: 0.2vw;
  padding-left: 1.5vw;
}

/** estilos scroll bar */
.tbl_item::-webkit-scrollbar {
  -webkit-appearance: none;
}

.tbl_item::-webkit-scrollbar:vertical {
  width: 0.3645833333333333vw;
}

.tbl_item::-webkit-scrollbar-button:increment,
.tbl_item::-webkit-scrollbar-button {
  display: none;
}

.tbl_item::-webkit-scrollbar:horizontal {
  height: 0.3645833333333333vw;
}

.tbl_item::-webkit-scrollbar-thumb {
  background-color: #797979;
  /*border-radius: 1.0416666666666665VW;*/
  border: 0.10416666666666667vw solid #f1f2f3;
}

.tbl_item::-webkit-scrollbar-track {
  border-radius: 0.3645833333333333vw;
}

.boxform {
  display: grid;
  margin-right: 4.375vw;
  margin-bottom: 0.8854166666666666vw;
}

p.btnRegresar {
  cursor: pointer;
  border: none;
  background: var(--color-1);
  color: var(--color-5);
  width: 6vw;
  padding: 0.7vw 0.7vw;
  text-align: center;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
  font-weight: 500;
  font-size: 0.7291666666666666vw;
  margin-right: 2vw;
}

button.alta {
  cursor: pointer;
  border: none;
  background: var(--color-1);
  color: var(--color-5);
  width: 6vw;
  height: 2.5vw;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
  font-weight: 600;
  font-size: 0.7291666666666666vw;
}

.btn_añadir2 {
  cursor: pointer;
  border: none;
  background: var(--color-1);
  color: var(--color-5);
  width: 11.1vw;
  height: 2.5vw;
  text-align: left;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
}
.btn_añadir2 img {
  width: 1.3031249999999999vw;
  height: 1.3031249999999999vw;
  margin-left: 1.5vw;
  padding-top: 0.6vw;
}
.btn_añadir2 p {
  font-weight: 500;
  font-size: 0.7291666666666666vw;
  padding-bottom: 0.2vw;
  padding-left: 1.5vw;
}

.btn_añadir2 p span {
  font-weight: 700;
}

#uploadimg button {
  border: none !important;
  background: var(--color-5);
}

input[type="file"] {
  display: none;
}

.filelabel {
  padding: 0.8vw 0vw;
  width: 14.716666666666668vw;
  border: 0.052083vw solid var(--color-2);
  border-radius: 0vw 1.5vw 1.5vw 0vw;
  color: var(--color-4) !important;
  font-size: 0.7291666666666666vw;
  font-weight: 500 !important;
  padding-left: 1.09375vw;
  cursor: pointer;
}

.filelabelname {
  padding: 0.8vw 1vw;
  border-left: none;
  width: auto;
  cursor: pointer;

  color: var(--color-4) !important;
  font-size: 0.7291666666666666vw;
  font-weight: 500 !important;
  padding-left: 1.09375vw;
}

/*select */

.select-container {
  margin-right: 1.9270833333333335vw;
}
#select-items {
  width: 8.90625vw;
  height: 1.563vw;
  margin-top: 0.2vw;
  border: 0px;
  background-color: var(--color-4);
  border-radius: 0.5vw;
  color: var(--color-5);
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  border: none;
  padding-left: 0.938vw;
  display: flex;
  align-items: center;
  background-image: url("./assets/flecha.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 8.6vw;
}
input#select-ch {
  display: none;
}
.options-cont {
  display: none;
}
#select-ch:checked ~ .options-cont {
  display: flex;
  position: absolute;
  padding: 0.573vw 1.25vw;
  flex-direction: column;
  background-color: white;
  width: 7.3vw;
}
#select-items.rounded {
  border-radius: 0.5vw 0.5vw 0 0;
}
.option-btn {
  cursor: pointer;
  font-size: 0.625vw;
  line-height: 1.25vw;
}
.option-btn:hover {
  background: gainsboro;
}

.activeradius {
  border-radius: 0.5vw 0.5vw 0vw 0vw !important;
}

#select-items option {
  background: white;
  color: #8d8d8d;
}

/*select */
</style>
